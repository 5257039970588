import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// Reactstrap
import Breadcrumb from "../../components/Common/Breadcrumb2";
// Import menuDropdown
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import { APP_ROUTES } from "helpers/routeHelpers";
import { NotificationStatus } from "../../constants";
import { GetNotification, ReadNotification } from "api/api.service";
import {
    updateNotificationCount,
    showRightSidebarAction,
    toggleLeftmenu,
    changeSidebarType
} from "store/actions";

// import images
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";

import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";
import LogoLight from "../../assets/images/aiAPaiT/sidebar/logo-primary-better-white.svg";
import LogoSmall from "../../assets/images/aiAPaiT/sidebar/dashboard.png";

//i18n
import { withTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const CHAT = "Chat";

const Header = (props) => {
    const history = useHistory();
    const params = useParams();
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [notificationMessages, setNotificationMessages] = useState([]);
    const [totalNotifications, setTotalNotifications] = useState(0);
    const [isCaseDetailIdSame, setIsCaseDetailIdSame] = useState(false);

    const notifications = async () => {
        try {
            setIsLoading(true);
            let res = await GetNotification({ page: page, direction: "DESC", column: "updatedAt" });
            setTotalNotifications(res?.total);
            let notificationsMerged =
                page === 1 ? res?.data : [...notificationMessages, ...res?.data];
            setNotificationMessages(notificationsMerged);
            props.updateNotiCount();
        } catch (err) {
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        notifications();
    }, [page]);

    const readNotification = async (item) => {
        const { trademarkId, id, readStatus, chat, notificationType } = item;

        if (notificationType == CHAT) {
            history.push({
                pathname: `${APP_ROUTES.CHATS}/${chat?.id}`
            });
        } else {
            history.push({
                pathname: `${APP_ROUTES.CASE_MANAGEMENT_DETAILS}/${trademarkId}`,
                state: { isSameId: isCaseDetailIdSame }
            });
        }

        if (readStatus === NotificationStatus.getDisplayTextKey(0)) {
            try {
                let res = await ReadNotification(item?.id);
                props.updateNotiCount();
                let cloneNotification = [...notificationMessages];
                cloneNotification = cloneNotification.map((noti) => {
                    if (noti.id == id) {
                        item.readStatus = NotificationStatus.getDisplayTextKey(1);
                    }
                    return noti;
                });

                setNotificationMessages([...cloneNotification]);
            } catch (err) {
                console.log("errrorr:", err);
            }
        }
        setIsCaseDetailIdSame(!isCaseDetailIdSame);
    };

    function toggleFullscreen() {
        if (
            !document.fullscreenElement &&
            /* alternative standard method */ !document.mozFullScreenElement &&
            !document.webkitFullscreenElement
        ) {
            // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    function tToggle() {
        var body = document.body;
        if (window.screen.width <= 992) {
            body.classList.toggle("vertical-collpsed");
            body.classList.toggle("sidebar-enable");
        } else {
            body.classList.toggle("vertical-collpsed");
            body.classList.toggle("sidebar-enable");
        }
    }

    const handlePageClick = () => {
        setPage((prevPage) => prevPage + 1);
    };

    const updatePage = () => {
        setPage(1);
    };

    return (
        <React.Fragment>
            <header id="page-topbar" className="gt-topbar">
                <div className="navbar-header">
                    <div className="d-flex">
                        <div className="navbar-brand-box  d-md-block ">
                            <Link to="/" className="logo logo-light">
                                <span className="logo-sm ">
                                    <img src={LogoSmall} alt="aiapaitLogo" width={40} height={38} />
                                </span>
                                <span className="logo-lg">
                                    <img src={LogoLight} height={49} />
                                </span>
                            </Link>
                        </div>
                        <button
                            type="button"
                            onClick={() => {
                                tToggle();
                            }}
                            className="btn btn-sm px-3 header-item "
                            id="vertical-menu-btn"
                        >
                            <i className="bx bx-bar-chart bx-rotate-90 bx-sm"></i>
                        </button>
                        <div>
                            <Breadcrumb />
                        </div>
                    </div>
                    <div className="d-flex">
                        <NotificationDropdown
                            isLoading={isLoading}
                            totalNotifications={totalNotifications}
                            handlePageClick={handlePageClick}
                            redirectNotification={readNotification}
                            notificationsData={notificationMessages}
                            page={page}
                            fetchNotificatoins={notifications}
                            updatePage={updatePage}
                        />
                        <ProfileMenu />
                    </div>
                </div>
            </header>
        </React.Fragment>
    );
};

Header.propTypes = {
    changeSidebarType: PropTypes.func,
    leftMenu: PropTypes.any,
    leftSideBarType: PropTypes.any,
    showRightSidebar: PropTypes.any,
    showRightSidebarAction: PropTypes.func,
    t: PropTypes.any,
    toggleLeftmenu: PropTypes.func
};

const mapStatetoProps = (state) => {
    const { layoutType, showRightSidebar, leftMenu, leftSideBarType } = state.Layout;
    return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

const mapDispatchToProps = (dispatch) => ({
    updateNotiCount: () => dispatch(updateNotificationCount()),
    showRightSidebarAction: () => dispatch(showRightSidebarAction()),
    toggleLeftmenu: () => dispatch(toggleLeftmenu()),
    changeSidebarType: () => dispatch(changeSidebarType())
});

export default connect(mapStatetoProps, mapDispatchToProps)(Header);
