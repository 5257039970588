import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import toast from "react-hot-toast";
import { useParams, useHistory } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    Col,
    Row,
    Card,
    AccordionBody,
    Label,
    AccordionItem,
    UncontrolledAccordion
} from "reactstrap";
import { finalizeMediaUtil, initializeMediaUtil, uploadOnS3 } from "utils/mediaUtils";
import { removeSpaceUnderscoreCharacterFromString } from "utils/commonUtils";
import { createStepTwo } from "api/api.service";
import { CaseRejection } from "components/CaseRejectionModal";
import { APP_ROUTES } from "helpers/routeHelpers";

import { ImageUpload } from "components/ImageUpload";
import { ImageTypes } from "../../constants";
import { TextInput } from "components/Input";
import { CustomButton } from "components/CustomButton";
import { CustomAccordionHeader } from "./Accordion";

import UploadLogo from "../../assets/images/aiAPaiT/case-mangement/upload-logo.png";
import FormikErrorText from "components/FormikErrorText/FormikErrorText";
import {
    accordionStatus,
    buttonTitle,
    dateFormat,
    documentTypePayload,
    fieldRequirementMessage,
    responseMessages,
    stepTitle
} from "./Constants";

const KB_CONVERTER = 1024;

export default function POAApplication({
    defaultOpenAccord = [],
    title = "",
    targetId = "",
    isReject = false,
    isPA = false,
    processNO,
    dataObj,
    data_description,
    rejectionReason = "",
    stepSubmissionStepUpdater = () => {},
    refetchStepsAPIHandler = () => {}
}) {
    const params = useParams();
    const history = useHistory();

    const [rejectionSuccessModal, setRejectionSuccessModal] = useState(false);
    const [powerAttorneyImage, setPowerAttorneyImage] = useState({
        image: "",
        fileName: undefined,
        imageError: undefined
    });
    const [applicationFieldImage, setApplictionFieldImage] = useState({
        image: "",
        fileName: undefined,
        imageError: undefined
    });
    const [errorType, setErrorType] = useState(0);
    const [isSubmittingStepTwo, setIsSubmittingStepTwo] = useState(false);

    // formik validation for input fields
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            stepFees: "",
            legalisation: false
        },
        validationSchema: Yup.object({
            stepFees: Yup.number()
                .integer()
                .required(fieldRequirementMessage.STEP_FEE)
                .min(1, fieldRequirementMessage.STEP_FEE_MIN_LIMIT)
                .max(1000, fieldRequirementMessage.STEP_FEE_MAX_LIMIT),
            legalisation: Yup.bool()
        }),
        onSubmit: (values) => {
            dataObj.amount = values.stepFees;
            dataObj.legalisation = values.legalisation;
            handleStepPOAApplicationSubmission();
        }
    });

    // file uploading
    const FileUploadHander = (file) => {
        let imageError = undefined;
        let fileName = undefined;
        let image = "";
        if (file) {
            const uploadedFileType = file?.name
                ?.split(".")
                [file?.name?.split(".").length - 1]?.toLowerCase();
            let fileSize = file && file?.size;
            fileSize = fileSize / KB_CONVERTER;
            if (!ImageTypes.includes(uploadedFileType)) {
                imageError = responseMessages.FILE_TYPE_ERROR;
                fileName = undefined;
                image = "";
            } else {
                fileName = removeSpaceUnderscoreCharacterFromString(file?.name);
                imageError = "";
                image = file;
                image.fileName = fileName;
            }
        }
        return { imageError, image, fileName };
    };

    // handle file input change
    const handleFileChange = (e, imageObjUpdater, type) => {
        e.preventDefault();
        let file = e.target.files[0];
        const { image, imageError, fileName } = FileUploadHander(file);
        imageObjUpdater({ image, imageError, fileName });
        if (errorType === 3) {
            type == documentTypePayload.TRADEMARK_POWER_ATTORNEY && !applicationFieldImage.image
                ? setErrorType(2)
                : type == documentTypePayload.TRADEMARK_APPLICATION &&
                  !powerAttorneyImage.image &&
                  setErrorType(1);
        } else if (errorType === 1) {
            type == documentTypePayload.TRADEMARK_POWER_ATTORNEY && !applicationFieldImage.image
                ? setErrorType(2)
                : type == documentTypePayload.TRADEMARK_POWER_ATTORNEY &&
                  applicationFieldImage.image &&
                  setErrorType(0);
        } else if (errorType === 2) {
            type == documentTypePayload.TRADEMARK_APPLICATION && !powerAttorneyImage.image
                ? setErrorType(1)
                : type == documentTypePayload.TRADEMARK_APPLICATION &&
                  powerAttorneyImage.image &&
                  setErrorType(0);
        }
    };

    // hanlde remove image
    const removeImage = (imageObjUpdater) => {
        imageObjUpdater({ image: "", imageError: undefined, fileName: undefined });
    };

    // media upload on server
    const handleMediaUpload = async (file) => {
        console.log("file", file);
        return initializeMediaUtil(file).then(async (res) => {
            const credentials = res;
            await uploadOnS3(file, credentials);
            return await finalizeMediaUtil(credentials?.mediaId);
        });
    };

    // handle Form Submission using API on Backend
    const handleStepPOAApplicationSubmission = async () => {
        if (powerAttorneyImage.image === "" && applicationFieldImage.image === "") {
            setErrorType(3);
            return;
        } else if (powerAttorneyImage.image === "") {
            setErrorType(1);
            return;
        } else if (applicationFieldImage.image === "") {
            setErrorType(2);
            return;
        } else {
            let payloadObj = { ...dataObj };
            payloadObj.amount = Number(dataObj?.amount);
            payloadObj.expiryDate = moment(dataObj.expiryDate).format(dateFormat.expiryDate);
            payloadObj.documents = [];
            setIsSubmittingStepTwo(true);
            stepSubmissionStepUpdater();
            if (applicationFieldImage.image) {
                let imageRes = await handleMediaUpload(applicationFieldImage.image);
                payloadObj.documents.push({
                    documentType: documentTypePayload.TRADEMARK_APPLICATION,
                    mediaId: imageRes.id
                });
            }
            if (powerAttorneyImage.image) {
                let imageRes = await handleMediaUpload(powerAttorneyImage.image);
                payloadObj.documents.push({
                    documentType: documentTypePayload.TRADEMARK_POWER_ATTORNEY,
                    mediaId: imageRes.id
                });
            }
            createStepTwo(params?.id, payloadObj)
                .then((res) => {
                    toast.success(responseMessages.SUCCESS_E_SIGNATURE);
                    stepSubmissionStepUpdater();
                    refetchStepsAPIHandler();
                })
                .catch((err) => {
                    setIsSubmittingStepTwo(false);
                    stepSubmissionStepUpdater();
                });
        }
    };

    // rejection modal close handling
    const rejectionModalCloseHandler = (isReject) => {
        isReject && history.push(APP_ROUTES.CASE_MANAGEMENT);
        setRejectionSuccessModal(false);
    };

    return (
        <>
            <div
                className={`case-detail-accordian-signature-con ${
                    isReject && "accordion-disable-class"
                }`}
            >
                <UncontrolledAccordion defaultOpen={defaultOpenAccord} stayOpen>
                    <AccordionItem>
                        <CustomAccordionHeader
                            containerClassName="case-detail-accordian-container"
                            innerClassName="case-detail-accordian-header"
                            subContainerClassName="case-detail-accordian-header-status"
                            headerCount={processNO}
                            headerText={title}
                            status={isReject ? accordionStatus.REJECTED : accordionStatus.OPEN}
                            targetId={targetId}
                        />
                        <AccordionBody
                            accordionId={targetId}
                            className="case-detail-accordian-body-con"
                        >
                            <Card>
                                <form onSubmit={formik.handleSubmit}>
                                    <Row>
                                        {isPA && (
                                            <>
                                                <Col md={3}>
                                                    <div className="d-flex">
                                                        <div className="case-detail-accordian-body">
                                                            <div>Power of Attorney field</div>
                                                            <ImageUpload
                                                                image={UploadLogo}
                                                                inputId={1}
                                                                onChange={(e) =>
                                                                    handleFileChange(
                                                                        e,
                                                                        setPowerAttorneyImage,
                                                                        documentTypePayload.TRADEMARK_POWER_ATTORNEY
                                                                    )
                                                                }
                                                                fileName={
                                                                    powerAttorneyImage?.fileName
                                                                }
                                                                imageError={
                                                                    powerAttorneyImage?.imageError
                                                                }
                                                                removeImageHandler={() =>
                                                                    removeImage(
                                                                        setPowerAttorneyImage
                                                                    )
                                                                }
                                                                errorType={
                                                                    errorType === 1 ||
                                                                    errorType === 3
                                                                        ? fieldRequirementMessage.UPLOAD_FILE
                                                                        : ""
                                                                }
                                                                disabled={isSubmittingStepTwo}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col md={3}>
                                                    <div className="d-flex">
                                                        <div className="case-detail-accordian-body">
                                                            <div>Application Field</div>
                                                            <ImageUpload
                                                                image={UploadLogo}
                                                                inputId={2}
                                                                onChange={(e) =>
                                                                    handleFileChange(
                                                                        e,
                                                                        setApplictionFieldImage,
                                                                        documentTypePayload.TRADEMARK_APPLICATION
                                                                    )
                                                                }
                                                                fileName={
                                                                    applicationFieldImage?.fileName
                                                                }
                                                                imageError={
                                                                    applicationFieldImage?.imageError
                                                                }
                                                                removeImageHandler={() =>
                                                                    removeImage(
                                                                        setApplictionFieldImage
                                                                    )
                                                                }
                                                                errorType={
                                                                    errorType === 2 ||
                                                                    errorType === 3
                                                                        ? fieldRequirementMessage.UPLOAD_FILE
                                                                        : ""
                                                                }
                                                                disabled={isSubmittingStepTwo}
                                                            />
                                                        </div>
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                        {!isPA && (
                                            <>
                                                <Col md={12}>
                                                    <div className="d-flex">
                                                        <div className="case-detail-accordian-body">
                                                            <div>
                                                                {" "}
                                                                {title === stepTitle.PUBLICATION
                                                                    ? "Trademark Publication Form"
                                                                    : "Trademark Registration"}
                                                            </div>
                                                            <div className="case-detail-accordian-body-img size-modifier">
                                                                <div>Upload File</div>
                                                                <img
                                                                    src={UploadLogo}
                                                                    alt="UploadFile"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </>
                                        )}
                                        <Col md={6}>
                                            <div className="bg-white p-3 pt-4 case-details-accordian-date-field">
                                                <Label>Add Fee for this Step (USD)</Label>
                                                <div className="mb-3">
                                                    <TextInput
                                                        placeholder="Add Fee (USD)"
                                                        name={"stepFees"}
                                                        type="number"
                                                        className="hide-icon"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.stepFees}
                                                        disabled={isSubmittingStepTwo}
                                                        autoFocus
                                                        preAppendIconFile={"$"}
                                                    />
                                                    <FormikErrorText
                                                        formikInstance={formik}
                                                        fieldName={"stepFees"}
                                                    />
                                                </div>
                                            </div>
                                        </Col>
                                        {isPA && (
                                            <Col md={12}>
                                                <div className="pa-check-box">
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            name={"legalisation"}
                                                            checked={formik.values.legalisation}
                                                            onChange={(e) =>
                                                                formik.setFieldValue(
                                                                    "legalisation",
                                                                    e.target.checked
                                                                )
                                                            }
                                                            value={"Legalisation"}
                                                            disabled={isSubmittingStepTwo}
                                                        />
                                                        {"Legalisation"}
                                                    </label>
                                                </div>
                                            </Col>
                                        )}
                                    </Row>
                                    <hr />
                                    <Row>
                                        <div className="accordian-body-footer-btn">
                                            <CustomButton
                                                title={buttonTitle.SEND}
                                                className="trademark-body-footer-accept-button shadow-none me-3"
                                                color="#704DE7"
                                                type={"submit"}
                                                loading={isSubmittingStepTwo}
                                                disabled={isSubmittingStepTwo}
                                            />
                                            {title !== stepTitle.E_SIGNATURE ? (
                                                <CustomButton
                                                    title={buttonTitle.REJECT}
                                                    className="trademark-body-footer-reject-button shadow-none"
                                                    color="#E64A4A"
                                                    onClick={() => {
                                                        setRejectionSuccessModal(true);
                                                    }}
                                                />
                                            ) : null}
                                        </div>
                                    </Row>
                                </form>
                            </Card>
                            {isReject ? (
                                <>
                                    <Card className="p-3 pt-0">
                                        <Row>
                                            <hr />
                                            <Col className="pt-2">
                                                <p className="rejection-reason">
                                                    <span className="rejection-typo-heading">
                                                        Rejection reason:{" "}
                                                    </span>
                                                    {rejectionReason || "-"}
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card>
                                </>
                            ) : null}
                            <CaseRejection
                                modalCloseHandler={rejectionModalCloseHandler}
                                modalActivator={rejectionSuccessModal}
                                stepId={data_description}
                                setIsRefetchingRequired={refetchStepsAPIHandler}
                                title={title}
                            />
                        </AccordionBody>
                    </AccordionItem>
                </UncontrolledAccordion>
            </div>
        </>
    );
}

// handling prop types
POAApplication.propTypes = {
    openAccord: PropTypes.string,
    toggleButtonHandler: PropTypes.func,
    title: PropTypes.string,
    targetId: PropTypes.string,
    accordId: PropTypes.string,
    isReject: PropTypes.bool,
    isPA: PropTypes.bool,
    processNO: PropTypes.string,
    setRejectionSuccessModal: PropTypes.func
};
